<template>
    <div class="max-w-screen-xl max-h-screen-tablet-cross-axis mx-auto py-2" v-on:click="click">
        <div v-if="seconds == 0" class=" sticky top-0 sticy-header-background" style="z-index: 99;">
            <Header :back="false" :language="postsData.lang" @langchanged="langchanged_" :languageVisibility="false"/>
            <div class="text-center mt-64">
                <img v-bind:src="getLogo()" alt="Logo" class="w-44 mx-auto">
                <h2 class="font-heading text-h2 text-redDamask dark:text-redDamask mt-10 mb-2">{{ $t('language.selectTitle') }}</h2>
                <p class="font-body text-bodyM text-sepiaBlack dark:text-halfColonialWhite">{{ $t('language.text') }}</p>
                <Language flag tablet :language="postsData.lang" @langchanged="langchanged_" class="mt-6"/>
                <Button xl :text="$t('language.saveBtn')" buttonType="primary" v-on:click="startTimer" fullWidth class="mt-24"/>
            </div>
        </div>
        <section v-else>
            <Header :back="false" :language="postsData.lang" 
                @langchanged="langchanged_"
                :modeChanged="modeChanged">
            </Header>
            <!-- <input type='text' id='quicksearchInput' placeholder="Keresés" v-model="quicksearchInput" ref="input" v-on:change="searchChange">
            <Language flag tablet /> -->
            <ul class="grid grid-cols-4 justify-items-center">
                <li v-for="post of posts" :key="post.id">
                    <EntityList :content="post" />
                </li>
            </ul>
            <pagination :pagination="pagination"
                @prev="--postsData.page; getPosts();"
                @next="postsData.page++; getPosts();">
            </pagination>
        </section>
    </div>
</template>

<script>

import Header from "@/components/Header.vue";
import pagination from "@/components/Pagination.vue";
import EntityList from "@/components/tablet/EntityList.vue";
import Button from '@/components/Button.vue';
import Language from '@/components/Language';

export default {
    name: "Tablet",
    components: {
        Header,
        'pagination': pagination,
        EntityList,
        Button,
        Language,
    },
    mounted() {
        console.log(this.$route.query.category);
        localStorage.setItem('tablet_category', this.$route.query.category ? this.$route.query.category : '');
        const query_seconds = this.$route.query.seconds;
        if (query_seconds) {
            if (query_seconds == 0) {
                this.seconds = 0;
            } else {
                this.startTimer();
            }
        } else {
            this.seconds = 0;
        }

        this.getPosts();
    },
    data(){
        return {
            posts: [],
            baseURL: process.env.VUE_APP_AXIOS_URL,
            postsData: {
                per_page: 8,
                page: 1,
                lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
                search: '',
                category: '',
            },
            pagination: {
                prevPage: '',
                nextPage: '',
                totalPages: '',
                from: '',
                to: '',
                total: '',
            },
            seconds: 0,
            standard_sec: 60,
        }
    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        getPosts() {
            this.postsData.category = this.$route.query.category;
            this.axios.get(this.baseURL + `/wp-json/wp/v2/tablets`, {params: this.postsData}, {
                headers: {
                        'Access-Control-Allow-Origin': '*'
                }
            }).then(response => {
                this.posts = response.data;
                this.configPagination(response.headers);
            }).catch( (error) => {
                console.log(error);
            });
        },
        configPagination(headers) {
            this.pagination.total = +headers['x-wp-total'];
            this.pagination.totalPages = +headers['x-wp-totalpages'];
            this.pagination.from = this.pagination.total ? ((this.postsData.page - 1) * this.postsData.per_page) + 1 : ' ';
            this.pagination.to = (this.postsData.page * this.postsData.per_page) > this.pagination.total ? this.pagination.total : this.postsData.page * this.postsData.per_page;
            this.pagination.prevPage = this.postsData.page > 1 ? this.postsData.page : '';
            this.pagination.nextPage = this.postsData.page < this.pagination.totalPages ? this.postsData.page + 1 : '';
        },
        langchanged_(value) {
            this.postsData.page = 1;
            this.postsData.lang = value;
            this.getPosts();
        },
        modeChanged(value) {
            this.$emit('modeChanged', value);
        },
        startTimer() {
            this.$router.replace({ query: {seconds: "60", category: this.$route.query.category} })
            this.seconds = this.standard_sec;
            setTimeout(this.countdown, 1000);
        },
        countdown() {
            console.log(this.seconds);
            if (this.seconds > 0) {
                this.seconds -= 1;
                setTimeout(this.countdown, 1000);
            }
        },
        click() {
            if (this.seconds > 0) {
                this.seconds = this.standard_sec;
            }
        },
        handleScroll (event) {
            if (this.seconds > 0) {
                this.seconds = this.standard_sec;
            }
        },
        getLogo: function() {
            const element = document.getElementById("app");
            if (element.classList.contains('darkMode')) {
                return require("../assets/logo_dark.png");
            } else {
                return require("../assets/logo_light.png");
            }
        },
    },
};
</script>
